import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/luth-pamela/1.jpeg';
import blogd2 from '../../assets/images/2024/luth-pamela/2.jpeg';
import blogd3 from '../../assets/images/2024/luth-pamela/3.jpeg';
import blogd4 from '../../assets/images/2024/luth-pamela/5.jpeg';
// import blogd5 from '../../assets/images/2024/maryland-luth/5.jpeg';
// import blogd6 from '../../assets/images/2024/maryland-luth/6.jpeg';
// import blogd7 from '../../assets/images/2024/maryland-luth/7.jpeg';
// import blogd8 from '../../assets/images/2024/maryland-luth/8.jpeg';
// import blogd9 from '../../assets/images/2024/maryland-luth/9.jpeg';
// import blogd10 from '../../assets/images/2024/maryland-luth/11.jpeg';
// import blogd11 from '../../assets/images/2024/maryland-luth/10.jpeg';
// import blogd12 from '../../assets/images/2024/maryland-luth/12.jpeg';
// import blogd13 from '../../assets/images/2024/maryland-luth/13.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  //   const subs = [
  //     {
  //       SNO: '1',
  //       PRESENTERS: 'DR. A.K Toye',
  //       SESSION: 'CASE 1',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. O.D Tom- AYEGUNLE',
  //       SESSION: 'CASE 2',
  //     },
  //     {
  //       SNO: '3',
  //       PRESENTERS: 'DR. T. AYO- Olagunju',
  //       SESSION: 'Timing of initiation of Dialysis AKI',
  //     },
  //     {
  //       SNO: '4',
  //       PRESENTERS: 'Dr. D. Ezuduemoih',
  //       SESSION: 'Timing of initiation of Dialysis CKD',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. B. T. BELLO/ Dr. R. W. BRAIMOH',
  //       SESSION: 'Overview',
  //     },
  //   ];

  //   const subRows = subs.map((element) => (
  //     <tr key={element.SNO}>
  //       <td>{element.SNO}</td>
  //       <td>{element.PRESENTERS}</td>
  //       <td>{element.SESSION}</td>
  //     </tr>
  //   ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Lagos University Teaching Hospital (LUTH) Welcomes Dr. Pamela
                  Agbamu Back After Successful Training in Obstetric
                  Anesthesiology in Israel
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Lagos University Teaching Hospital (LUTH) proudly welcomed Dr.
                  Pamela Agbamu, after she successfully completed her
                  specialised training in Obstetric Anesthesiology in Israel.
                  Despite the ongoing war in the region, Dr. Agbamu's dedication
                  and commitment to advancing her expertise have been exemplary.
                  <br />
                  {/* <Table fontSize='md' striped>
                    <thead>
                      <tr>
                        <th>SNO</th>
                        <th>PRESENTERS</th>
                        <th>SESSION</th>
                      </tr>
                    </thead>
                    <tbody>{subRows}</tbody>
                  </Table> */}
                  Dr. Agbamu's training in Israel, a country renowned for its
                  advanced medical education and research, has equipped her with
                  cutting-edge skills and knowledge in the field of Obstetric
                  Anesthesiology but also earned her a distinguished award in
                  recognition of her exceptional achievements. Her return marks
                  a significant milestone for LUTH, as her enhanced expertise
                  will greatly benefit our Patients and contribute to the
                  overall improvement of maternal and neonatal care.
                  <br />
                  "We are delighted to welcome Dr. Pamela Agbamu back to LUTH,"
                  said Prof Wasiu Adeyemo, the Chief Medical Director (CMD) of
                  LUTH. "Her perseverance and dedication to her profession, even
                  in the face of adversity, are truly inspiring. We are
                  confident that her advanced training will elevate the quality
                  of care we provide to our Patients." The CMD also noted that
                  her return is not only a cause for celebration but also an
                  evidence to LUTH's dedication to supporting and developing its
                  staff.
                  <br />
                  {/* Mark Gladwin, the Dean of the School of Medicine and Vice
                  President of Medical Affairs at the University of Maryland,
                  emphasized the importance of extending their longstanding
                  partnership with Nigeria. "For over 20 years, the University
                  of Maryland has partnered with Nigeria through the Institute
                  for Human Virology to combat infectious diseases like HIV and
                  tuberculosis. Today, we are here to address the next
                  generational challenge—non-communicable diseases. Nigeria has
                  the highest prevalence of sickle cell disease globally, and
                  our goal is to improve the lives of patients affected by this
                  and other NCDs through research, capacity building, and
                  collaborative care." */}
                  <br />
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Dr. Agbamu was well-regarded in Israel for her diligence and
                  excellent interpersonal skills, which facilitated her
                  integration and learning. She also collaborated on research
                  projects that will contribute to her future work at LUTH.
                  <br />
                  On her part, Dr. Agbamu expressed her gratitude for the
                  opportunity to train in Israel and her excitement to apply her
                  new skills at LUTH. "I am deeply grateful for the support I
                  received from the Management of LUTH and my colleagues during
                  my training. I look forward to bringing my enhanced knowledge
                  and skills to our hospital and making a positive impact on the
                  lives of our Patients."
                  <br />
                  This inspiring example highlights LUTH’s commitment to
                  fostering the professional development of its staff while
                  maintaining the highest standard of care for our Patients.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>

                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p> */}
                {/* 
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd13} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                Form left to right - Professor
                Wasiu Lanre Adeyemo (FAS) and Dr. Pamela Agbamu
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Servicom</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Lagos University Hospital World Breastfeeding Week (WBW)  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
